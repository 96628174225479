import * as React from 'react';

import HeroImg from '../../../../src/images/ourStory/header_photo.png';

const HeaderContent = (props) => {
  return (
    <>
      <div className={'headerContent backstoryHeaderContent'}>
        <div className={'heroImage'}>
          <img src={HeroImg} alt={'Saxophonist'} />
        </div>
        <h1 className='title'>
          Believe in your vision,{' '}
          <span className={'titleUppercase'}>
            believe in <br /> your goals
          </span>{' '}
          and go for them,
          <br /> even if you have to risk everything.
        </h1>
        <h2 className='underTitle'>Mateo Granić (GRADIVARI Founder)</h2>
      </div>
    </>
  );
};

export default HeaderContent;
