import * as React from 'react';

import Layout from '../../layout';
import BgImageOvalBorder from '../../../images/oval_border.svg';

const ThirdSection = (props) => {
  return (
    <section className='outStoryThirdSection'>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <h2 className='textTitle'>The idea for SaxMax is born.</h2>
        <p className='text'>
          The small tool that is supposed to{' '}
          <strong>solve one of the biggest problems for saxophonists</strong>{' '}
          all over the world when learning and playing.
        </p>
        <p className='text'>
          <strong>That is exactly why I founded this company.</strong>
        </p>
        <p className='text'>
          To enable musicians all over the world to{' '}
          <strong>reach their full potential</strong>
          and that of their saxophone. That is why <strong>
            GRADIVARI
          </strong>{' '}
          exists.
        </p>
        <p className='text'>
          Oh, and regarding the “Flight of the Bumblebee” by Rimsky-Korsakov:{' '}
          <strong>I can play it now.</strong> At the speed of a quarter = 208
          BPM.
        </p>
        <p className='text'>
          And now <strong>you can do it too.</strong> Or whatever else you wish
          to play — <strong>now you can play the “unplayable”.</strong>
        </p>
        <p className='text'>
          <strong>Rise above,</strong> <br />
          <strong>Mateo Granić</strong>
        </p>
      </Layout>
    </section>
  );
};

export default ThirdSection;
