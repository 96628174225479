import * as React from 'react';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import Portrait from '../../../../src/images/ourStory/oval_photo.png';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';
import BgImageStar from '../../../images/star.svg';

import SectionBg1 from '../../../images/__new/about/img2.jpg';

const FirstSection = (props) => {
  return (
    <>
      <section className='underHeaderRectangle'>
        <div className='firstSection'>
          <div className='bgRectangle'></div>
          <Layout>
            <div className='textContainer'>
              <p className='sectionTitleText'>RISE ABOVE YOUR LIMITS</p>
              <p className='whiteText'>
                Hello musicians. Here we are now to empower you. To minimize
                your struggle. Maximize your sax. Because we believe you don't
                have to be a genius to play a genius – to play like a genius. We
                believe: Playing your sax doesn't have to be pain but a
                pleasure. That's why it's called playing. Yeah, we believe in
                the easy way to learn, to play, to grow. For the Good Vibrations
                on your SAX. Stop dreaming. Start playing like a Pro.{' '}
                <span className='yellowText'>You. Are. Limitless.</span> So feel
                free to play wildly free.{' '}
                <span className='yellowText'>Push your Boundaries.</span> So
                let’s simplify SAX forever. Welcome to the easy way to learn to
                play. Now it’s your turn to play the unplayable. You must never
                doubt your ability to achieve anything. Become anything.
                Overcome anything. And inspire everything.
              </p>
              <p className='yellowText'>
                Rise above your boundaries. Rise about your doubts. Rise above
                your limits.
              </p>
            </div>
          </Layout>
        </div>
      </section>
      <section className={'outStoryIntroduction'}>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='bgImage'>
            <img
              className='floatBase image1'
              src={BgImageOval}
              role='presentation'
              alt='oval'
            />
            <img
              className='floatBase image2'
              src={BgImageOval}
              role='presentation'
              alt='oval'
            />
            <img
              className='pulseBase image3'
              src={BgImageOvalBorder}
              role='presentation'
              alt='oval'
            />
            <img
              className='pulseBase image4'
              src={BgImageOvalBorder}
              role='presentation'
              alt='oval'
            />
            <img
              className='floatBase image5'
              src={BgImageOval}
              role='presentation'
              alt='oval'
            />
            <img
              className='pulseBase image6'
              src={BgImageOvalBorder}
              role='presentation'
              alt='oval'
            />
            <img
              className='floatBase image7'
              src={BgImageOval}
              role='presentation'
              alt='oval'
            />
            <img
              className='floatBase image8'
              src={BgImageOval}
              role='presentation'
              alt='oval'
            />
          </div>
          <div className='sectionTitle'>
            <h2 className='sectionTitleText'>
              From the Flight&nbsp;of&nbsp;the
              <span className='titleTextUnder'>Bumblebee to&nbsp;SaxMax.</span>
            </h2>
          </div>
          <HorizontalSectionLine />
          <div className='description'>
            <div className='imageTextWrapper'>
              <div className='imgContainer'>
                <div className='bgImageContainer'>
                  <img
                    className='rotateBase image1'
                    src={BgImageStar}
                    role='presentation'
                    alt='star'
                  />
                  <img
                    className='rotateBase image2'
                    src={BgImageStar}
                    role='presentation'
                    alt='star'
                  />
                  <img
                    className='rotateBase image3'
                    src={BgImageStar}
                    role='presentation'
                    alt='star'
                  />
                </div>
                <img src={Portrait} alt='Gradivari founder' />
              </div>
              <div className='imageTextContainer'>
                <p className='textHeader'>
                  Many good ideas are born from a problem.
                </p>
                <p className='text'>
                  And it was the same with our start-up{' '}
                  <strong>GRADIVARI</strong> and with our first product{' '}
                  <strong>SaxMax:</strong> I couldn't progress further as an
                  artist, so <strong>I started looking for a solution</strong> —
                  for me and all the other musicians.
                </p>
                <p className='text'>
                  <strong>Vienna, 2015:</strong> I am standing in my apartment,
                  my fingers floating over the saxophone that has accompanied me
                  for half my life. It is refined with 24-carat gold and
                  specially tailored to my style of music — and it is not an
                  instrument.{' '}
                  <strong>It is my life that I hold in my hands.</strong> And
                  that is exactly what is running through my fingers.
                </p>
              </div>
            </div>
            <div className='textContainer'>
              <p className='text'>
                <strong>
                  I am frustrated. I doubt myself and my ability as a musician.
                </strong>{' '}
                It's been like this for a long time: no matter how hard I work
                on my playing technique, no matter how many extra hours I play
                on my saxophone, no matter what I try —{' '}
                <strong>I always reach a point where I can't continue.</strong>{' '}
                I can't progress beyond that point, I can't develop my playing
                technique further, I cannot explore since all the possibilities
                that music and my saxophone offer are not transferring over.
              </p>
              <p className='text'>
                I ask myself: <strong>is this it?</strong> All those years of
                hard work, the trembling, sweating, bleeding, music studies,
                master classes, concerts, scholarships, awards, and the trust
                and praise of my mentors all this just to realize that I can't
                make any more musical progress?
              </p>
              <div className='quoteContainer'>
                <div className='quoteSeparator' />
                <p className='quoteText'>
                  „Mateo is an excellent saxophonist and graduated summa cum
                  laude from our university. He has always impressed me very
                  much with his performance, which combines perfect technique
                  and virtuosity with natural musicality and a confident sense
                  of style.“
                </p>
              </div>
              <p className='text'>
                These were the words of the music professor, Heinz Medjimorec.
              </p>
              <p className='text'>
                Everything was still possible at that time. I was a music
                student, <strong>I was curious about the music world</strong>{' '}
                and its possibilities and I wanted to gain new musical
                experiences.
              </p>
              <div className='quoteContainer'>
                <div className='quoteSeparator' />
                <p className='quoteText'>
                  "A spirited magician on the saxophone, Mateo Granić is a born
                  musician. He has the extraordinary gift of addressing his
                  audience in a very personal way. The natural gift of making
                  music and the experience of musical stimuli are in harmony
                  with his talent and personality." - Croatian evening newspaper{' '}
                  <strong>Večernji list</strong>
                </p>
              </div>
              <p className='text'>
                A born musician. A magician with an extraordinary gift — and now
                I'm standing here, frustrated, doubting myself and{' '}
                <strong>thinking of GIVING UP.</strong> At this point, I don't
                know that this is exactly what musicians all over the world are
                going through.
              </p>
              <p className='text'>
                Give up?{' '}
                <strong>Music is and has always been EVERYTHING for me.</strong>{' '}
                As far back as I can think, I have always held an instrument in
                my hands. Let's travel back to my childhood: while other
                children play with soccer balls, cars or dolls, I play on an
                instrument — always and everywhere. If there is no instrument
                nearby... - well, then any object that I can convert into an
                instrument will do.
              </p>
              <p className='text'>
                <strong>
                  I am nine years old when I start my music education.
                </strong>{' '}
                I am a teenager when I play as a volunteer member in the
                services of the first police orchestra of the Republic of
                Croatia. And{' '}
                <strong>
                  even when the Croatian Homeland War breaks out, I play:
                </strong>{' '}
                while the bombs fall on Dalmatia, we continue to work and make
                music, on and on, without breaks. We play to comfort ourselves
                and others. <strong>We play to motivate each other.</strong> And
                we play to escape from war for a few sonatas.
              </p>
              <p className='text'>
                Because of my love and passion for music,{' '}
                <strong>I have dedicated my life to music.</strong> I have never
                done anything else.
              </p>
              <p className='text'>
                I always wanted to <strong>make music.</strong> For me, music is
                what the call of the wild was for Jack London. I have always
                followed the inner call of music.
              </p>
              <p className='text'>
                All my life, I have not just been a musician, I have lived
                music. <strong>I was obsessed with music</strong> and until that
                moment when I reached the limits of my playing technique, I
                lived safely and confidently in a big bubble that I had built up
                with all the scholarships and grants, with all the praise from
                my mentors and the press. I now realize this painfully.
              </p>
            </div>
          </div>
        </Layout>
      </section>
    </>
  );
};

export default FirstSection;
