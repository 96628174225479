import * as React from 'react';

import Layout from '../../layout';

import SectionImage from '../../../images/__new/about/img3.jpg';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';

const SecondSection = (props) => {
  return (
    <section className='outStorySecondSection'>
      <div className='bgRectangle' />
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image3'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image4'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image5'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='imageContainer'>
          <img src={SectionImage} alt='Section' loading='lazy' />
          <p className='imageDescription'>Back in the days</p>
        </div>
        <div className='textContainer'>
          <p className='text'>
            <strong>
              Every musician gets to this point where he can't progress.
            </strong>{' '}
            Beethoven, Mozart, Coltrane, Parker, Hendrix — all of them
          </p>
          <p className='text'>
            What makes the difference is that some give up and others{' '}
            <strong>
              look for a way to free themselves to reach their full potential.
            </strong>{' '}
            The potential that lies within them and their instrument.
          </p>
          <p className='text'>
            When I realized this, I knew that there was always a solution for
            every problem. So I set out to find that solution. And then I find a
            YouTube video:
          </p>
          <p className='text'>
            <strong> “Fastest violinist in the world.”</strong> The video caught
            my attention. Mine and that of 10 million others all over the world.
            And I think: Wow, I can do that as well!
          </p>
          <p className='text'>
            So, <strong>I apply to the Guinness World Records.</strong> They
            send me the “record package”. Contents: the notes of the “Flight of
            the Bumblebee” by Rimski-Korsakow, specially arranged for the
            saxophone. Pretty damn hard to play: there are passages in the notes
            that are UNPLAYABLE on sax even at a moderate tempo.
          </p>
          <p className='text'>
            I train hard. I try to beat the world record. It does not work. The{' '}
            <strong>
              saxophone makes it impossible to use your fingers to play this
              piece.
            </strong>{' '}
            Absolutely impossible. After a year of trials and trying, I am about
            to give up.
          </p>
          <p className='text'>
            <strong>But then I have an idea.</strong> If I manage to{' '}
            <strong>push my sax even further away from my body</strong> while
            playing, I can use my right hand more freely. Maybe I could even{' '}
            <strong>use my thumb to play.</strong> So, I try it. The tool that
            is supposed to help me with this: a stuffed belt pouch.
          </p>
          <p className='text'>
            <strong>Bingo!</strong> It's not perfect, but it works: I can
            already <strong>play much more freely, easier and faster.</strong> I
            can use my hand in a way{' '}
            <strong>that was never possible before.</strong> I am now even
            developing <strong>a completely new playing technique.</strong>
          </p>
          <p className='text'>
            I am thrilled. I call the new playing technique that I developed{' '}
            <strong>“Criss-Cross-Fingering”.</strong> And this technique makes
            it possible for me to play previously impossible parts on the
            saxophone. Suddenly{' '}
            <strong>
              I can play scores that were previously considered “unplayable”
            </strong>{' '}
            on the saxophone.
          </p>
        </div>
      </Layout>
    </section>
  );
};

export default SecondSection;
