import * as React from 'react';

import Layout from '../../layout';

import SectionBg from '../../../images/__new/about/img4.jpg';

const FourthSection = () => {
  return (
    <section className='backstoryFourthSection'>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              LEARN HOW TO PLAY MUSIC SHOULD NOT BE A PAIN BUT A PLEASURE.
              <span className='yellowText'>
                THAT’S WHY IT’S CALLED PLAYING.
              </span>
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default FourthSection;
