import React, { useEffect } from 'react';

import '../scss/main.scss';

import Seo from '../components/inc/seo';

import Header from '../components/header';
import HeaderContent from '../components/backstory/headerContent';
import FirstSection from '../components/backstory/firstSection';
import SecondSection from '../components/backstory/secondSection';
import ThidrSection from '../components/backstory/thirdSection';
import FourthSection from '../components/backstory/fourthSection';
import EntertainSection from '../components/entertainSection';

import TopBtn from '../components/inc/topBtn';

import Footer from '../components/footer';

const BackstoryPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 500,
      });
    }, 300);
  }, []);

  return (
    <>
      <Seo />
      <Header backstory>
        <HeaderContent />
      </Header>
      <main>
        <FirstSection />
        <SecondSection />
        <ThidrSection />
        <FourthSection />
        <EntertainSection />
      </main>
      <Footer />
      <TopBtn />
    </>
  );
};

export default BackstoryPage;
